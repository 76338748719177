import React, { useContext, useState, useRef } from 'react';
import { Menu } from 'antd';
import useOnClickOutside from 'library/hooks/useOnClickOutside';
import { NavLink, withRouter } from 'react-router-dom';
import { AuthContext } from 'context/AuthProvider';
import {
  USER_PROFILE_PAGE,
  USER_ACCOUNT_SETTINGS_PAGE,
  PUBLISHING_POST,
  USER_MESSAGES,
  USER_FAVORITES,
  USER_LISTING
} from 'settings/constant';

const ProfileMenu = ({ avatar, history }) => {
  const { logOut, user } = useContext(AuthContext);
  const [state, setState] = useState(false);
  const handleDropdown = () => {
    setState(!state);
  };
  const closeDropdown = () => {
    setState(false);
  };
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setState(false));
  function handleLogout() {
    logOut();
    history.push('/');
  }

  return (
    <div className="avatar-dropdown" ref={dropdownRef}>
      <div className="dropdown-handler" onClick={handleDropdown}>
        {avatar}
      </div>

      <Menu className={`dropdown-menu ${state ? 'active' : 'hide'}`}>
        <Menu.Item onClick={closeDropdown} key="0">
          <NavLink to={`${USER_PROFILE_PAGE}/${user.id}`}>Mi Perfil</NavLink>
        </Menu.Item>
        <Menu.Item onClick={closeDropdown} key="1">
          <NavLink to={USER_LISTING}>Mis Anuncios</NavLink>
        </Menu.Item>
        <Menu.Item onClick={closeDropdown} key="2">
          <NavLink to={PUBLISHING_POST}>Publicar Anuncio</NavLink>
        </Menu.Item>
        <Menu.Item onClick={closeDropdown} key="3">
          <NavLink to={USER_FAVORITES}>Favoritos</NavLink>
        </Menu.Item>
        <Menu.Item onClick={closeDropdown} key="4">
          <NavLink to={USER_MESSAGES}>Mensajes</NavLink>
        </Menu.Item>
        <Menu.Item onClick={closeDropdown} key="5">
          <NavLink to={USER_ACCOUNT_SETTINGS_PAGE}>Preferencias</NavLink>
        </Menu.Item>
        <Menu.Item key="6">
          <button onClick={handleLogout}>Cerrar Sesión</button>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default withRouter(ProfileMenu);

import React, { useContext, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import { AuthContext } from 'context/AuthProvider';
import useOnClickOutside from 'library/hooks/useOnClickOutside';

import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  USER_PROFILE_PAGE,
  PRICING_PLAN_PAGE,
  PUBLISHING_POST,
  USER_ACCOUNT_SETTINGS_PAGE,
  USER_MESSAGES,
  USER_LISTING,
  USER_FAVORITES
} from 'settings/constant';

const MobileMenu = ({ className, history }) => {
  const { loggedIn, logOut, user } = useContext(AuthContext);
  const [state, setState] = useState(false);
  const handleDropdown = () => {
    setState(!state);
  };
  const closeDropdown = () => {
    setState(false);
  };
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setState(false));
  function handleLogout() {
    logOut();
    // history.push('/');
  }

  return (
    <Menu className={className}>
      <Menu.Item key="0">
        <NavLink exact to={HOME_PAGE}>
          Inicio
        </NavLink>
      </Menu.Item>
      <Menu.Item key="1">
        <NavLink exact to={`${LISTING_POSTS_PAGE}`}>
          Descubrir
        </NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink to={`${PUBLISHING_POST}`}>
          Publicar Anuncio
        </NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink to={`${PRICING_PLAN_PAGE}`}>
          {loggedIn ? "Subscripciones" : "Planes"}
        </NavLink>
      </Menu.Item>
      {loggedIn &&
        <>
          <Menu.Item key="4">
            <NavLink to={`${USER_PROFILE_PAGE}/${user.id}`}>Mi Perfil</NavLink>
          </Menu.Item>
          <Menu.Item key="5">
            <NavLink to={USER_LISTING}>Mis Anuncios</NavLink>
          </Menu.Item>
          <Menu.Item key="6">
            <NavLink to={USER_FAVORITES}>Favoritos</NavLink>
          </Menu.Item>
          <Menu.Item key="7">
            <NavLink to={USER_MESSAGES}>Mensajes</NavLink>
          </Menu.Item>
          <Menu.Item key="8">
            <NavLink to={USER_ACCOUNT_SETTINGS_PAGE}>Preferencias</NavLink>
          </Menu.Item>
          <Menu.Item key="9">
            <button onClick={handleLogout}>Cerrar Sesión</button>
          </Menu.Item>
        </>
      }
    </Menu>
  );
};

export default MobileMenu;

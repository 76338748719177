import React, { useContext, useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { AuthContext } from './context/AuthProvider';
import Layout from './container/Layout/Layout';
import { decryptData } from 'utilities/utilities';
import {
  LOGIN_PAGE,
  REGISTRATION_PAGE,
  FORGET_PASSWORD_PAGE,
  RESET_PASSWORD_PAGE,
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  SINGLE_POST_PAGE,
  ADD_POST_PAGE,
  UPDATE_POST_PAGE,
  USER_PROFILE_PAGE,
  USER_ACCOUNT_SETTINGS_PAGE,
  PRIVACY_PAGE,
  PRICING_PLAN_PAGE,
  PAYMENT_CONFIRMATION_PAGE,
  PUBLISHING_POST,
  USER_MESSAGES,
  USER_FAVORITES,
  USER_LISTING
} from './settings/constant';
require('dotenv').config();

/**
 *
 * Public Routes
 *
 */
const Loading = () => null;

const routes = [
  {
    path: HOME_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Home" */ './container/Home/Home'),
      loading: Loading,
      modules: ['Home'],
    }),
    exact: true,
  },
  {
    path: `${LOGIN_PAGE}/:id?/:token?`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SignIn" */ './container/Auth/SignIn/SignIn'
        ),
      loading: Loading,
      modules: ['SignIn'],
    }),
  },
  {
    path: REGISTRATION_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SignUp" */ './container/Auth/SignUp/SignUp'
        ),
      loading: Loading,
      modules: ['SignUp'],
    }),
  },
  {
    path: FORGET_PASSWORD_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "ForgetPassword" */ './container/Auth/ForgetPassword'
        ),
      loading: Loading,
      modules: ['ForgetPassword'],
    }),
  },
  {
    path: `${RESET_PASSWORD_PAGE}/:id/:token`,

    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "ResetPassword" */ './container/Auth/ResetPassword'
        ),
      loading: Loading,
      modules: ['ResetPassword'],
    }),
  },
  {

    path: `${SINGLE_POST_PAGE}/:id/`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SinglePageView" */ './container/SinglePage/SinglePageView'
        ),
      loading: Loading,
      modules: ['SinglePageView'],
    }),
  },
  {
    path: LISTING_POSTS_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Listing" */ './container/Listing/Listing'),
      loading: Loading,
      modules: ['Listing'],
    }),
  },
  {
    path: `${USER_PROFILE_PAGE}/:id`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "UserDetailsViewPage" */ './container/User/AccountDetails/UserDetailsViewPage'
        ),
      loading: Loading,
      modules: ['UserDetailsViewPage'],
    }),
  },
  {
    path: PRIVACY_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "privacy" */ './container/Privacy/Privacy'),
      loading: Loading,
      modules: ['Privacy'],
    }),
  },
  {
    path: PRICING_PLAN_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Pricing" */ './container/Pricing/Pricing'),
      loading: Loading,
      modules: ['Pricing'],
    }),
  },
  {
    path: PAYMENT_CONFIRMATION_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "PaymentConfirmation" */ './container/PaymentConfirmation/PaymentConfirmation'),
      loading: Loading,
      modules: ['PaymentConfirmation'],
    }),
  },
  {
    path: PUBLISHING_POST,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Publishing" */ './container/Publishing/Publishing'),
      loading: Loading,
      modules: ['Publishing'],
    }),
    exact: true,
  },

];

/**
 *
 * Protected Route Component
 *
 */
const AddListing = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddListing" */ './container/AddListing/AddListing'
    ),
  loading: Loading,
  modules: ['AddListing'],
});

const UserAccountSettingsPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "UserAccountSettingsPage" */ './container/User/AccountSettings/UserAccountSettingsPage'
    ),
  loading: Loading,
  modules: ['UserAccountSettingsPage'],
});

const UpdatePost = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddListing" */ './container/UpdatePost/UpdatePost'
    ),
  loading: Loading,
  modules: ['UpdatePost'],
});

const MyMessages = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MyMessages" */ './container/User/AccountSettings/MyMessages/MyMessages'
    ),
  loading: Loading,
  modules: ['MyMessages'],
});

const Favorites = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "UserFavItemListsSettings" */ './container/User/AccountDetails/UserFavItemListsSettings'
    ),
  loading: Loading,
  modules: ['UserFavItemListsSettings'],
});

const UserListings = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "UserItemlListSettings" */ './container/User/AccountDetails/UserItemListsSettngs'
    ),
  loading: Loading,
  modules: ['UserItemlListSettings'],
});

/**
 *
 * Not Found Route Component
 *
 */

const NotFound = Loadable({
  loader: () =>
    import(/* webpackChunkName: "NotFound" */ './container/404/404'),
  loading: Loading,
  modules: ['NotFound'],
});

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { loggedIn, user } = useContext(AuthContext);
  return (
    <Route
      render={(props) =>
        loggedIn && user ? <Component {...props} /> : <Redirect to={LOGIN_PAGE} />
      }
      {...rest}
    />
  );
};

const ProtectedRouteAdmin = ({ component: Component, ...rest }) => {
  const { loggedInAdmin } = useContext(AuthContext);
  return (
    <Route
      render={(props) =>
        loggedInAdmin ? <Component {...props} /> : <Redirect to={PRIVACY_PAGE} />
      }
      {...rest}

    />
  );
};

/**
 *
 * Overall Router Component
 *
 */

const Routes = () => {
  const { user, loggedIn, setLoginUser, setToken, setTheFavorites } = useContext(AuthContext);

  useEffect(() => {
    const userData = localStorage.getItem('ps_auth');
    if (userData) {
      const salt = process.env.REACT_APP_SALT;
      const originalData = decryptData(userData, salt);

      if (originalData) {
        originalData.avatar = originalData.profile_picture;
        setLoginUser(originalData, true);
        setToken(originalData.token, true);
        setTheFavorites(originalData.favorites);
      }
    }
  }, [])

  return (
    <Layout>
      <Switch>
        {routes.map(({ path, component, exact = false }) => (
          <Route key={path} path={path} exact={exact} component={component} />
        ))}
        <ProtectedRoute path={`${ADD_POST_PAGE}/:post_type`} component={AddListing} />
        <ProtectedRoute path={`${UPDATE_POST_PAGE}/:post_id`} component={UpdatePost} />
        <ProtectedRoute
          path={USER_ACCOUNT_SETTINGS_PAGE}
          component={UserAccountSettingsPage}
        />
        <ProtectedRoute
          path={USER_MESSAGES}
          component={MyMessages}
        />
        <ProtectedRoute
          path={USER_FAVORITES}
          component={Favorites}
        />
        <ProtectedRoute
          path={USER_LISTING}
          component={UserListings}
        />
        <Route component={NotFound} />
      </Switch>
    </Layout>
  );
};

export default Routes;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
export const AuthContext = React.createContext();

const AuthProvider = (props) => {
  let history = useHistory();
  const [loggedIn, setLoggedIn] = useState(false);
  const [loggedInAdmin, setLoggedInAdmin] = useState(false);
  const [user, setUser] = useState({});
  const [favorites, setFavorites] = useState([]);
  const [token, setToken] = useState({});
  const [doneAuth, setDoneAuth] = useState();

  const setTheFavorites = (favorites) => {
    setFavorites(favorites);
  }

  const setLoginUser = (params, refreshPage, facebook) => {
    signIn(params, refreshPage, facebook);

    if (!refreshPage) {
      history.push(`/`);
    }
  };

  const setUserToken = (params, refreshPage) => {
    setToken(params);
    if (!refreshPage) {
      history.push(`/`);
    }
  };

  const signIn = (params, refreshPage = false, facebook) => {
    const the_user = {
      id: params.id,
      email: params.email,
      first_name: params.first_name,
      last_name: params.last_name,
      name: params.name,
      gender: params.gender,
      avatar: params.profile_picture,
      roleId: params.roleId,
      date_of_birth: params.date_of_birth,
      telephone: params.telephone,
      cover_picture: params.cover_picture ? params.cover_picture : "https://www.wallpapertip.com/wmimgs/47-479874_free-data.jpg",
      about_me: params.about_me,
      facebook_url: params.facebook_url,
      instagram_url: params.instagram_url,
      twitter_url: params.twitter_url,
      linkedin_url: params.linkedin_url,
      tiktok_url: params.tiktok_url,
      pinterest_url: params.pinterest_url,
    };
    /*     if (facebook){
          the_user.cover_picture = "https://www.wallpapertip.com/wmimgs/47-479874_free-data.jpg";
          the_user.avatar = "https://moonvillageassociation.org/wp-content/uploads/2018/06/default-profile-picture1.jpg";
        } */
    setUser(the_user);
    setLoggedIn(true);
    if (!refreshPage) {
      history.push(`/`);
    }
  };

  const signInAdmin = (params) => {
    setUser(params);
    setLoggedInAdmin(true);
    history.push(`/`);
  };

  const setTheUser = (params) => {
    setUser(params);
  }

  const signUp = (params) => {
    let user;
    if (params.avatar) {
      user = params
    } else {
      user = {
        id: params.id,
        email: params.email,
        first_name: params.first_name,
        last_name: params.last_name,
        name: params.name,
        gender: params.gender,
        avatar: params.profile_picture,
        roleId: params.roleId,
        date_of_birth: params.date_of_birth,
        telephone: params.telephone,
        cover_picture: params.cover_picture,
        about_me: params.about_me,
        facebook_url: params.facebook_url,
        instagram_url: params.instagram_url,
        twitter_url: params.twitter_url,
        linkedin_url: params.linkedin_url,
        tiktok_url: params.tiktok_url,
        pinterest_url: params.pinterest_url
      };
    }
    setUser(user);
    setLoggedIn(true);
    history.push(`/`);
  };

  const logOut = () => {
    setUser(null);
    setToken(null);
    setLoggedIn(false);
    setLoggedInAdmin(false);
    Cookies.remove('placeshared');
    localStorage.removeItem("ps_auth");
    localStorage.removeItem("ps_utilities");
  };

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        loggedInAdmin,
        logOut,
        signIn,
        signInAdmin,
        signUp,
        user,
        favorites,
        setTheUser,
        setLoginUser,
        setLoggedIn,
        setTheFavorites,
        token,
        setToken,
        setUserToken,
        setDoneAuth,
        doneAuth
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;

import React, { useState, useContext } from 'react';
import Sticky from 'react-stickynode';
import { withRouter } from 'react-router-dom';
import { IoIosClose } from 'react-icons/io';
import { Button, Drawer } from 'antd';
import Logo from 'components/UI/Logo/Logo';
import Text from 'components/UI/Text/Text';
import TextLink from 'components/UI/TextLink/TextLink';
import Navbar from 'components/Navbar/Navbar';
import { AuthContext } from 'context/AuthProvider';
import { LayoutContext } from 'context/LayoutProvider';
import useWindowSize from 'library/hooks/useWindowSize';
import { USER_PROFILE_PAGE } from 'settings/constant';
import AuthMenu from './AuthMenu';
import MainMenu from './MainMenu';
import MobileMenu from './MobileMenu';
import ProfileMenu from './ProfileMenu';
import NavbarSearch from './NavbarSearch';
import HeaderWrapper, {
  MobileNavbar,
  CloseDrawer,
  AvatarWrapper,
  AvatarImage,
  AvatarInfo,
  LogoArea,
} from './Header.style';

export default withRouter(function Header({ location }) {
  const [{ searchVisibility }] = useContext(LayoutContext);
  const { loggedIn, user } = useContext(AuthContext);
  const { loggedInAdmin } = useContext(AuthContext);
  const { width } = useWindowSize();
  const [state, setState] = useState(false);
  let avatarImg, profileName;

  if (user) {
    avatarImg = user.avatar ? user.avatar : user.profile_picture;
    profileName = user.name;
  }

  const sidebarHandler = () => {
    setState(!state);
  };

  const LogoIcon = () => (
    <svg viewBox="0 0 61 71" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.4267 65.6697L8.89114 54.5259C6.40817 52.948 4.87547 50.2284 4.82896 47.318L4.44479 23.2791C4.39827 20.3687 5.84442 17.6723 8.27756 16.1327L25.4613 5.25941C28.2121 3.5188 31.7587 3.54643 34.5658 5.33033L52.1014 16.474C54.5844 18.052 56.1171 20.7716 56.1636 23.682L56.5477 47.7209C56.5943 50.6313 55.1481 53.3277 52.715 54.8673L35.5312 65.7406C32.7804 67.4812 29.2338 67.4536 26.4267 65.6697Z" stroke="white" stroke-width="7" />
      <path d="M29.1155 52.2312L15.6833 27.1582C15.1482 26.1594 15.8702 24.9516 17.0029 24.9508L43.7861 24.9314C44.9188 24.9306 45.6447 26.1374 45.1129 27.1369L31.7619 52.2293C31.1988 53.2878 29.6821 53.2889 29.1155 52.2312Z" stroke="white" stroke-width="7" />
    </svg>
  );

  const headerType = location.pathname === '/' ? 'transparent' : 'default';

  return (
    <HeaderWrapper>
      <Sticky
        top={headerType === 'transparent' ? -1 : 0}
        innerZ={10001}
        activeClass="isHeaderSticky"
      >
        {width > 991 ? (
          <Navbar
            logo={
              <>
                {headerType === 'transparent' &&
                  <LogoIcon />
                }
                <Logo
                  withLink
                  linkTo="/"
                  src="/images/logo-alt.svg"
                  title="LugarCompartido"
                />
              </>
            }
            navMenu={<MainMenu />}
            authMenu={<AuthMenu />}
            isLogin={loggedIn}
            isLoginAdmin={loggedInAdmin}
            avatar={<Logo src={avatarImg} />}
            profileMenu={<ProfileMenu avatar={<Logo src={avatarImg} />} />}
            headerType={headerType}
            searchComponent={<NavbarSearch />}
            location={location}
            searchVisibility={searchVisibility}
          />
        ) : (
          <MobileNavbar className={headerType}>
            <Button
              className={`hamburg-btn ${state ? 'active' : ''}`}
              onClick={sidebarHandler}
            >
              <span />
              <span />
              <span />
            </Button>
            <NavbarSearch />
            <LogoArea>
              <>
                {headerType === 'default' && <LogoIcon />}
                <Logo
                  withLink
                  linkTo="/"
                  src="/images/logo-alt.svg"
                  title="LugarCompartido"
                />
              </>
            </LogoArea>
            <Drawer
              placement="left"
              closable={false}
              onClose={sidebarHandler}
              onClick={sidebarHandler}
              width="285px"
              className="mobile-header"
              visible={state}
            >
              <CloseDrawer>
                <button onClick={sidebarHandler}>
                  <IoIosClose />
                </button>
              </CloseDrawer>
              {loggedIn && user ? (
                <AvatarWrapper>
                  <AvatarImage>
                    <Logo src={avatarImg} />
                  </AvatarImage>
                  <AvatarInfo>
                    <Text as="h3" content={profileName} />
                    <TextLink
                      link={`${USER_PROFILE_PAGE}/${user.id}`}
                      content="Ver Mi Perfil"
                    />
                  </AvatarInfo>
                </AvatarWrapper>
              ) : (
                <AuthMenu className="auth-menu" />
              )}
              <MobileMenu className="main-menu" />
            </Drawer>
          </MobileNavbar>
        )}
      </Sticky>
    </HeaderWrapper>
  );
});

import React from 'react';
import Logo from 'components/UI/Logo/Logo';
import Footers from 'components/Footer/Footer';
import FooterMenu from './FooterMenu';

const Footer = () => {
  return (
    <Footers
      logo={
        <>
          Un sitio de
          <Logo
            withLink
            linkTo="/"
            src="/images/logo-alt.svg"
            title="PlaceShared"
          />
        </>
      }
      menu={<FooterMenu />}
      copyright={`Copyright @ ${new Date().getFullYear()} PlaceShared.com`}
    />
  );
};

export default Footer;

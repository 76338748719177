import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import { AuthContext } from 'context/AuthProvider';

import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  USER_PROFILE_PAGE,
  PRICING_PLAN_PAGE,
  ADD_POST_PAGE,
  PUBLISHING_POST,
  USER_MESSAGES,
  USER_FAVORITES
} from 'settings/constant';

const MainMenu = ({ className }) => {
  const { loggedIn } = useContext(AuthContext);

  return (
    <Menu className={className}>
      <Menu.Item key="0">
        <NavLink exact to={`${LISTING_POSTS_PAGE}`}>
          Descubrir
        </NavLink>
      </Menu.Item>
      <Menu.Item key="1">
        <NavLink to={`${PUBLISHING_POST}`}>Publicar</NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink to={`${PRICING_PLAN_PAGE}`}>{loggedIn ? "Subscripciones" : "Planes"}</NavLink>
      </Menu.Item>
      {loggedIn &&
        <>
          <Menu.Item key="3">
            <NavLink to={`${USER_MESSAGES}`}>Mensajes</NavLink>
          </Menu.Item>
          <Menu.Item key="4">
            <NavLink to={`${USER_FAVORITES}`}>Favoritos</NavLink>
          </Menu.Item>
        </>
      }
    </Menu>
  );
};

export default MainMenu;

// General Page Section
export const HOME_PAGE = '/';
export const UserS_PAGE = '/Users';

// Post Listing and Single Page 
export const LISTING_POSTS_PAGE = '/listing';
export const SINGLE_POST_PAGE = '/post';

// User Pages 
export const USER_PROFILE_PAGE = '/profile';
export const USER_ACCOUNT_SETTINGS_PAGE = '/account-settings';
export const USER_PROFILE_EDIT_PAGE = '/edit';
export const USER_IMAGE_EDIT_PAGE = '/change-image';
export const USER_PASSWORD_CHANGE_PAGE = '/change-password';
export const USER_PROFILE_DELETE = '/delete';
export const USER_PROFILE_FAVOURITE = '/favourite-post';
export const USER_MESSAGES = '/messages';
export const USER_FAVORITES = '/favorites';
export const USER_LISTING = '/my-listing';
export const USER_PROFILE_CONTACT = '/contact';
export const USER_POSTS = '/user-posts';
export const USER_PROFILE_REVIEWS = '/reviews';

// Other Pages
export const PRICING_PLAN_PAGE = '/pricing-plan';
export const PUBLISHING_POST = '/publishing';
export const PRIVACY_PAGE = '/privacy';
export const ADD_POST_PAGE = '/add-post/post-type';
export const UPDATE_POST_PAGE = '/update-post';
export const PAYMENT_CONFIRMATION_PAGE = '/payment-confirmation';

// Login / Registration Page / Reseset Password
export const LOGIN_PAGE = '/sign-in';
export const REGISTRATION_PAGE = '/sign-up';
export const RESET_PASSWORD_PAGE = '/reset-password';
export const FORGET_PASSWORD_PAGE = '/forget-password';
